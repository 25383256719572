<template>
  <v-container fluid>
    <v-toolbar rounded>
      <v-toolbar-title class="text-h5" elevation="20">Demande de diffusion</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="openCreateModal" color="#c23b78" dark small>Créer</v-btn>
    </v-toolbar> 

    <v-card-title class="mt-4 pa-0 pb-2">
      <div class="d-flex align-center" style="width: 100%; gap: 1rem;">
        <v-text-field 
          dense 
          v-model="search" 
          append-icon="mdi-magnify" 
          label="Rechercher" 
          single-line
          hide-details
          clearable
        >
        </v-text-field>
      <!-- bouton de filtre -->
        <v-btn-toggle 
            dense
          >
          <v-menu offset-x left :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on">
                <v-icon color="primary" class="mr-2" dark small >mdi-filter</v-icon>
                <v-chip color="primary" small
                style="font-size: 0.7rem; color: #fff;">Filtres</v-chip>
              </v-btn>
            </template>
            <v-list>
              <v-list-item-group v-for="filter in btnFilter" :key="filter.value">
                <v-list-item>
                  <v-list-item-title 
                    :class="stepColor(filter.value) === 'primary' ? 'primary--text' : stepColor(filter.value) === 'warning' ? 'warning--text' : 'success--text'"
                  >{{ filter.text }}</v-list-item-title>
                  <v-list-item-action>
                    <v-checkbox v-model="selectedFilters" :value="filter.value" :color="stepColor(filter.value)"></v-checkbox>
                  </v-list-item-action>
                </v-list-item> 
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-btn-toggle>
      </div>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="diffusions"
      :search="search"
      :items-per-page="10"
      class="elevation-1"
    > 
      <template v-slot:item.createdAt="{ item }">
        <v-chip
          small
          color="#c23b78"
          dark
        >{{ item.createdAt | formatDate }}</v-chip>
      </template>
      <template v-slot:item.repo.sysAdminValidation.dateValidation="{ item }">
        <v-chip
          small
          v-if="item.repo && item.repo.sysAdminValidation"
          color="success"
          dark
        >{{ item.repo.sysAdminValidation.dateValidation | formatDate }}</v-chip>
        <v-chip
          small
          v-else
          color="warning"
          dark
        >
        {{ item.repo.requestDate | formatDate }}
        </v-chip>
      </template>
      <template v-slot:item.repo.module="{ item }">
        <div v-for="module in item.repo.module" :key="module">
          {{ module }}
          <v-btn @click="goToLink(module)"
            icon 
            title="Ouvrir le lien git"
            x-small
            color="primary"
          >
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
        </div>
      </template>
      <!-- branch -->
      <template v-slot:item.repo.branch="{ item }">
        <v-chip
          small
          :color="branchColor(item)"
          dark
        >{{ item.repo.branch }}</v-chip>
      </template>
      <!-- fichier d'installation -->
      <template v-slot:item.repo.fileInstallation="{ item }">
        <v-chip
          small
          :color="item.repo.fileInstallation && item.repo.fileInstallation ? 'success' : '#c23b78'"
          dark
        >{{ item.repo.fileInstallation && item.repo.fileInstallation ? item.repo.fileInstallation : 'Aucun fichier' }}</v-chip>
      </template>
      <!-- base concerné -->
      <template v-slot:item.baseList="{ item }">
        <v-menu :close-on-content-click="false" offset-y max-height="400">
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs">
                <template v-if="item.repo.baseList.length > 1">
                  {{ item.repo.baseList[0].name }} +{{ item.repo.baseList.length - 1 }}
                </template>
                <template v-else-if="item.repo.baseList.length === 1">
                  {{ item.repo.baseList[0].name }}
                </template>
                <template v-else>
                  Aucune base
                </template>
              </div>
            </template>
            <v-toolbar v-if="item.repo && item.repo.baseList.length > 1" color="primary" dark>
                <v-text-field
                  v-model="searchBase"
                  append-icon="mdi-magnify"
                  label="Rechercher une base"
                  single-line
                  hide-details
                ></v-text-field>
            </v-toolbar>
            <v-list>
              <v-list-item
                v-for="(base, index) in filteredBases(item.repo.baseList)"
                :key="index"
                > 
                <v-list-item-content> 
                  <v-list-item-title>{{ base.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
        </v-menu>
      </template>
       
      <template v-slot:item.actions="{ item }">
        <v-icon 
          class="mr-2" 
          small @click="openTimelineModal(item)"
          :title="item.repo.step === 3 ? 'Diffusion terminée' : item.repo.step === 2 ? 'Test de la diffusion' : item.repo.step === 1 ? 'Validation SysAdmin' : 'Demande de diffusion'"
          :color="stepColor(item.repo.step)"
        >mdi-timeline</v-icon>
        <v-icon class="mr-2" color="warning" small @click="openUpdateModal(item)">mdi-square-edit-outline</v-icon>
        <v-icon small color="error" @click="openDeleteModal(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>

     <!-- dialog Create -->
    <v-dialog v-model="dialogCreate" max-width="70%">
      <Create @close-modal="closeCreateModal" @reload="reload" />
    </v-dialog>
   <!-- dialog Update -->
    <v-dialog v-model="dialogUpdate" max-width="70%">
      <Update @close-update="closeUpdateModal" @reload="reload" />
    </v-dialog>
     <!-- dialog Delete  -->
    <v-dialog v-model="dialogDelete" max-width="25%">
      <Delete @close-modal="closeDeleteModal" @diffusion-deleted="reload" />
    </v-dialog>
   
    <!-- timeline -->
    <v-dialog v-model="dialogTimeline" max-width="60%">
      <v-card class="pa-4">
        <v-timeline class="pa-2">
          <v-timeline-item 
            small
            left
          >
            <v-chip slot="opposite"
              color="primary"            
            >{{ diffusion.createdAt | formatDate }}</v-chip>
              <v-card>
                <v-toolbar color="primary" dark height="40" class="mb-2">
                  <v-toolbar-title>Demande de diffusion</v-toolbar-title>
                </v-toolbar>
                <v-list-item-subtitle>
                  <v-icon>mdi-account</v-icon>
                  Demandeur : <span class="body-2">{{ diffusion.repo && diffusion.repo.user }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <v-icon>mdi-calendar</v-icon>
                  À diffuser le : <span class="body-2">{{ diffusion.repo && diffusion.repo.requestDate | formatDate }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <v-icon>mdi-source-branch</v-icon>
                  Branche Git : <span class="body-2">{{ diffusion.repo && diffusion.repo.branch }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <v-icon>mdi-database</v-icon>
                  Base concerné : <span class="body-2" v-for="base in diffusion.repo &&  diffusion.repo.baseList" :key="base.id">{{ base.name }}</span>
                </v-list-item-subtitle>
                <!-- fichier d'installation -->
                <v-list-item-subtitle>
                  <v-icon>mdi-file</v-icon>
                  Fichier d'installation : <span class="body-2">{{ diffusion.repo && diffusion.repo.fileInstallation ? diffusion.repo.fileInstallation : 'Aucun fichier' }}</span>
                </v-list-item-subtitle>
                  <!-- composant de liste vuetfy -->
                  <div v-if="diffusion.repo && diffusion.repo.module">  
                    <v-list-item-subtitle v-for="(module, index) in diffusion.repo.module" :key="index">
                      <v-icon>mdi-git</v-icon>
                      Lien Git : <span class="body-2">{{ module }}</span>
                    </v-list-item-subtitle>
                  </div>
              </v-card>
            <!-- </v-timeline-item-content> -->
          </v-timeline-item>
          <!-- etape de validation a droite -->
          <v-timeline-item v-if="diffusion.repo && diffusion.repo.step >= 1"
            small
            right
          >
          <v-chip slot="opposite"
            v-if="diffusion.repo && diffusion.repo.sysAdminValidation"
            color="#c23b78"
            dark
          >
            {{ diffusion.repo && diffusion.repo.sysAdminValidation && diffusion.repo.sysAdminValidation.dateValidation | formatDate }}
          </v-chip>
          <v-chip slot="opposite" 
            color="warning"
          v-else>En attente</v-chip>
            <v-card>
              <v-toolbar color="#c23b78" dark height="40">
                <v-toolbar-title>
                  {{ diffusion.repo && diffusion.repo.sysAdminValidation ? 'Validé' : 'Validation SysAdmin' }}
                </v-toolbar-title>
              </v-toolbar>
              <v-row v-if="!sysAdminRigth && diffusion.repo && !diffusion.repo.sysAdminValidation">
                <!-- en attente de validation du sys0 -->
                <v-col cols="12" class="pb-0">
                  <v-alert
                    icon="mdi-shield-lock-outline"
                    prominent
                    text
                    type="info" 
                    class="mb-0"
                  >
                    En attente de validation du SysAdmin
                  </v-alert>
                </v-col> 
                
              </v-row>
              <v-row class="d-flex align-center"
                v-else-if="diffusion.repo && !diffusion.repo.sysAdminValidation">
                <!-- champs date de diffusion -->
                <v-col cols="10" class="mt-4 pl-4">
                  <v-menu offset-y 
                    :close-on-content-click="false" 
                    left
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field 
                        v-model="sysAdminDateTime"
                        label="Date de diffusion"
                        v-on="on"
                        outlined
                        dense
                        append-icon="mdi-calendar"
                      > 
                      <!-- {{ formattedDateTime }} -->
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="sysAdminValidationDate"
                      :allowed-dates="allowedDates"
                      @change="formattedDateTime($event)"
                      :locale="$i18n.locale"
                      color="primary"
                    ></v-date-picker>
                      <v-time-picker 
                      v-model="sysAdminValidationTime"
                      @change="formattedDateTime($event)"
                      format="24hr"
                      scrollable
                    >
                    </v-time-picker>
                  </v-menu> 

                  <v-textarea
                    v-model="diffusion.comment"
                    label="Commentaire"
                    outlined
                    dense
                    rows="3"
                    hide-details
                  ></v-textarea>
                </v-col> 
                  <v-btn 
                    class="align-self-end mb-4"
                    title="Valider" 
                    small 
                    color="success" 
                    @click="validate(diffusion)"
                  >
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
              </v-row>
              <v-row v-else>
                <v-col cols="12" class="pt-6 pl-4">
                  <v-list-item-subtitle>
                    <v-icon>mdi-account</v-icon>
                    Validé par : <span class="body-2">{{ diffusion.repo && diffusion.repo.sysAdminValidation && diffusion.repo.sysAdminValidation.sysAdmin }}</span>
                  </v-list-item-subtitle>
                    <!-- date de diffusion  -->
                  <v-list-item-subtitle>
                    <v-icon>mdi-calendar</v-icon>
                    Date de diffusion : <span class="body-2">{{ diffusion.repo && diffusion.repo.sysAdminValidation.dateValidation}}</span>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <v-icon>mdi-comment-text</v-icon>
                    Commentaire : <span class="body-2">{{ diffusion.repo && diffusion.repo.sysAdminValidation && diffusion.repo.sysAdminValidation.comment ? diffusion.repo.sysAdminValidation.comment : 'Aucun commentaire' }}</span>
                  </v-list-item-subtitle>
                </v-col>
              </v-row>
            </v-card>
          </v-timeline-item>
          <v-timeline-item v-if="diffusion.repo && diffusion.repo.step >= 2"
            small
            left
          >
            <v-chip slot="opposite"
              v-if="diffusion.repo && diffusion.repo.devValidation"
              color="success"
              dark
            >
              {{ diffusion.repo && diffusion.repo.devValidation.dateDevTest | formatDate }}
            </v-chip>
            <v-chip slot="opposite" 
              color="warning"
            v-else>Non testé</v-chip>
            <v-card>
              <v-toolbar :color="diffusion.repo && diffusion.repo.devValidation ? 'success' : 'warning'" 
                dark 
                height="40">
                <v-toolbar-title>
                  {{ diffusion.repo && diffusion.repo.devValidation ? 'Testé' : 'Teste de la diffusion' }}
                </v-toolbar-title>
              </v-toolbar>
              <v-row class="d-flex align-center" 
                v-if="diffusion.repo && !diffusion.repo.devValidation &&
                (diffusion.repo.user === informations.firstname + ' ' + informations.lastname)
                ">
                <v-col cols="10" class="pt-6 pl-4">
                  <v-textarea
                    v-model="diffusion.devComment"
                    label="Commentaire"
                    outlined
                    dense
                    rows="3"
                    hide-details
                  ></v-textarea>
                </v-col> 
                <v-btn 
                  class="align-self-end mb-4"
                  title="Valider" 
                  small color="success"
                  @click="devTest(diffusion)"
                >
                  <v-icon>mdi-check</v-icon>
                </v-btn>
              </v-row>
              <v-row v-else-if="diffusion.repo && !diffusion.repo.devValidation &&
                (diffusion.repo.user !== informations.firstname + ' ' + informations.lastname)
                ">
                <v-col cols="12" class="pb-0">
                  <v-alert
                    icon="mdi-shield-lock-outline"
                    prominent
                    text
                    type="info" 
                    class="mb-0"
                  >
                    En attente de test du développeur
                  </v-alert>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12" class="pt-6 pl-4">
                  <v-list-item-subtitle>
                    <v-icon>mdi-account</v-icon>
                    Testé par : <span class="body-2">{{ diffusion.repo && diffusion.repo.devValidation.dev }}</span>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <v-icon>mdi-comment-text</v-icon>
                    Commentaire : <span class="body-2">{{ diffusion.repo && diffusion.repo.devValidation.devComment ? diffusion.repo.devValidation.devComment : 'Aucun commentaire' }}</span>
                  </v-list-item-subtitle>
                </v-col>
              </v-row>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import BasesService from '@/Services/SupportVision/BasesService';
import * as Vuex from "vuex";
import DiffusionService from "@/Services/SupportVision/DiffusionService"
import PlaneteOnlineService from "@/Services/PlaneteOnlineService";
import moment from 'moment';

export default {
  name: 'Clients',
  data() {
    return {
      dialogCreate: false,
      dialogUpdate: false,
      dialogDelete: false,
      dialogTimeline: false,
      diffusions: [],
      search: '',
      headers: [
        { text: 'Demandeur', value: 'repo.user', width: '160' },
        { text: 'Date de la demande', value: 'createdAt' },
        { text: 'Date de diffusion', value: 'repo.sysAdminValidation.dateValidation' },
        { text: 'Lien git', value: 'repo.module' },
        { text: 'Fichier d\'installation', value: 'repo.fileInstallation' },
        { text: 'Branche git', value: 'repo.branch' },
        { text: 'Base concerné', value: 'baseList' },
        { text: 'Actions', value: 'actions', sortable: false, width: '100' },
      ],
      diffusion: {},
      searchBase: '',
      sysAdminRigth: null, 
      sysAdminValidationDate: null,
      sysAdminValidationTime: null,
      selectedFilters: [],
      btnFilter: [
        { text: 'Validation SysAdmin', value: 1},
        { text: 'Test de la diffusion', value: 2},
        { text: 'Diffusion terminée', value: 3}
      ]
    };
  },
  components: {
    Create: () => import("@/Components/Views/SupportVision/DiffusionRepo/Create"),
    Update: () => import("@/Components/Views/SupportVision/DiffusionRepo/Update"),
    Delete: () => import("@/Components/Views/SupportVision/DiffusionRepo/Delete"),
  }, 
  filters: {
    formatDate(date) {
      // Définir les formats possibles d'entrée
      const formats = [
        moment.ISO_8601, // Format ISO (2024-05-27T11:37:40.097Z)
        "YYYY-MM-DD HH:mm", // Format avec espaces (2024-05-29 13:32)
        "DD/MM/YYYY - HH:mm", // Format avec slash et tiret (27/05/2024 - 13:16)
        "DD-MM-YYYY-HH:mm", // Format avec tirets (27-05-2024-13:37)
        "DD/MM/YYYY HH:mm", // Format avec slash et espace (27/05/2024 13:16)
        "DD-MM-YYYY HH:mm", // Format avec tirets et espace (27-05-2024 13:37)
      ];
      
      // Essayer de parser la date avec les formats définis
      const parsedDate = moment(date, formats, true);
      
      // Vérifier si la date est valide
      if (parsedDate.isValid()) {
        return parsedDate.format('DD/MM/YYYY - HH:mm');
      } else {
        // Si la date n'est pas valide, retourner un message d'erreur ou une valeur par défaut
        return 'Invalid date';
      }
    }
  },
  computed: {
    ...Vuex.mapState(['informations']),
    ...Vuex.mapMutations(['setSelectedDiffusionId']), 
    sysAdminDateTime() {
      // si this.sysAdminValidationDate et this.sysAdminValidationTime === null on retoune requestDate sinon on retourne la date et l'heure saisie
      return this.sysAdminValidationDate && this.sysAdminValidationTime ? 
        moment(this.sysAdminValidationDate).format('DD/MM/YYYY') + ' - ' + this.sysAdminValidationTime : 
        this.diffusion.repo.requestDate;
    },
  },
  watch: {
    selectedFilters() {
      this.reload();
    }
  },
  methods: {
    filteredBases(bases) {
      return bases.filter(base => base.name.toLowerCase().includes(this.searchBase.toLowerCase()));
    },
    openCreateModal() {
      this.dialogCreate = true;
    },
    closeCreateModal() {
      this.dialogCreate = false;
    },
    openUpdateModal(item) {
      this.$store.commit('setSelectedDiffusionId', item._id);
      this.dialogUpdate = true;
    },
    closeUpdateModal() {
      this.dialogUpdate = false;
    },
    openDeleteModal(item) {
      this.$store.commit('setSelectedDiffusionId', item._id);
      this.dialogDelete = true;
    },
    closeDeleteModal() {
      this.dialogDelete = false;
    },
    async openTimelineModal(item) {
      // getbyid
      const dif = await DiffusionService.getDiffusionById(item._id)
      this.diffusion = dif;
      this.dialogTimeline = true;
    },
    closeTimelineModal() {
      this.dialogTimeline = false;
    },
    allowedDates(date) {
      return moment(date).isSameOrAfter(moment(), 'day');
    },
    branchColor(branch) {
      // faire un switch pour changer la couleur de la branche
      switch (branch.repo.branch) {
        case 'master':
          return 'success';
        case 'preprod':
          return '#4363A3';
        case 'dev':
          return 'primary';
        default:
          return 'primary';
      }
    },
    stepColor(step) {
      // faire un switch pour changer la couleur de l'étape
      switch (step) {
        case 1:
          return 'primary';
        case 2:
          return 'warning';
        case 3:
          return 'success';
        default:
          return 'primary';
      }
    },
    formattedDateTime(e) {
      // quand on a une date et une heure on les concatène
      if (this.sysAdminValidationDate && this.sysAdminValidationTime) {
        let date = moment(this.sysAdminValidationDate).format('YYYY-MM-DD');
        let time = moment(this.sysAdminValidationTime).format('HH:mm');
        return date + ' ' + time;
      }
    },
    async validate(diffusion) { 
      if (moment(this.sysAdminDateTime, "DD/MM/YYYY - HH:mm").isBefore(moment())) {
        alert('La date de diffusion ne peut pas être dans le passé.');
        return;
      }
      diffusion.sysAdminValidation = {
        dateValidation: this.sysAdminDateTime,
        comment: diffusion.comment,
        sysAdmin: this.informations.firstname + ' ' + this.informations.lastname
      };
      await DiffusionService.updateDiffusion(diffusion._id, diffusion); 
      this.reload();
      const res = await DiffusionService.getDiffusionById(diffusion._id);
      this.diffusion = res;
    },
    async devTest(diffusion) {
      diffusion.devValidation = {
        dateDevTest: moment().format('DD-MM-YYYY-HH:mm'),
        devComment: diffusion.devComment,
        dev: this.informations.firstname + ' ' + this.informations.lastname
      };
      await DiffusionService.updateDiffusion(diffusion._id, diffusion);
      this.reload();
      const res = await DiffusionService.getDiffusionById(diffusion._id);
      this.diffusion = res;
    },
    async reload() {
      // Sending the selected filters to the server
      if (this.selectedFilters.length > 0) {
        await DiffusionService.postDiffusionsByStep(this.selectedFilters).then((response) => {
          this.diffusions = response;
        });
      } else {
        await DiffusionService.getDiffusions().then((response) => {
          this.diffusions = response;
        });
      } 

      await BasesService.getParams().then((response) => {
        this.sysAdminRigth = response[0].params.sysAdminMail.includes(this.informations.emailAddress);
      });

      const urlParams = new URLSearchParams(window.location.search);
      const myParam = urlParams.get('search');
      this.search = myParam ? myParam.trim() : '';

      // Reset the URL
      window.history.pushState({}, document.title, window.location.pathname);
    },
    // rendre cliquable le lien dans le tableau 
    async goToLink(link) {
      window.open(link, '_blank');
    },
  },
  async mounted() {
    await this.reload();
  },
};
</script>
<style scoped>
.v-list-item__subtitle {
  font-weight: bold;
  padding: 0.5rem;
}

</style>