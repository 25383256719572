import Api from "@/Services/Api";

export default {
    async getDiffusions() {
        let response = await Api().get("/support-vision/diffusion")
        return response.data
    },
    async getDiffusionById(id) {
        let response = await Api().get(`/support-vision/diffusion/${id}`)
        return response.data
    },
    async postDiffusionsByStep(data) {
        let response = await Api().post("/support-vision/diffusion/filters", {steps: data})
        return response.data
    },
    async createDiffusion(data) {
        let response = await Api().post(`/support-vision/diffusion/`, {repo: data})
        return response.data
    },
    async updateDiffusion(id, data) {
        let response = await Api().put(`/support-vision/diffusion/${id}`, {repo: data})
        return response.data
    },
    async removeDiffusion(id) {
        let response = await Api().delete(`/support-vision/diffusion/${id}`)
        return response
    },
    async saveConfig(data) {
        let response = await Api().post(`/support-vision/diffusion/config`, {config: data})
        return response.data
    },
    async getConfigs() {
        let response = await Api().get(`/support-vision/diffusion/configs`)
        return response.data
    }
}